import React, { Component } from 'react';
import './Footer.css';
import '../../Global.css';
import { TITLE } from '../../constants/Constants';
import { Container } from 'react-bootstrap';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }


  render() {
    let currentDate = new Date();
    return (
      <div>
        <Container className='footer flex-direction-column h-[300px] flex-justify-content-end m-auto'>
        <div className='w-100 border-color-white border-b mb-3'></div>
        <div className='flex-justify-content-space-between'>
          <div className='f-3 mb-2 text-bold '>{TITLE}</div>
          <div className=''>
            ©  {TITLE} {currentDate.getFullYear()}
          </div>
        </div>
      </Container>
      </div>
    );
  }
}

export default Footer;
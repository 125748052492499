import React, { Component } from 'react';
import './CMSNavigation.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import { handleGet } from '../../ajax/AjaxRequests';
import Folder from './Folder';
// import NavContextMenu from './context/NavContextMenu';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";




var _ = require('lodash');

class CMSNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          folders : [],
          scripts : [],
          contextMenuOptions : [],
        };

        this.getFoldersID = 2;
        this.getScriptsID = 12;
    }

    componentDidMount() {
      this.getFolders();
    }

    getFolders() {
      return handleGet(this.getFoldersID).then((response) => {
        let status = response['status'];

        if(status) {
          let folders = response['data'];
          return handleGet(this.getScriptsID).then((scriptsResponse) => {
            let scripts = scriptsResponse['data'];
  
            this.setState({
              folders : folders,
              scripts : scripts
            });
          });
          
        }
      });
      
    }

    onScriptClick(script) {
      this.setState({
        contextMenuOptions : [
          {
            title : 'Edit script',
            onClick : () => {this.editScript(script);},
          },
          {
            title : 'Delete script',
            onClick : () => {this.deleteScript(script);},
          },
        ],
      });
    }

    onFolderClick(folder) {

      let contextMenuOptions = [
          {
            title : 'Create folder',
            onClick : () => {this.createFolder(folder);},
          },
          {
            title : 'Create script',
            onClick : () => {this.createScript(folder);},
          }
        ];

        if(folder.parent_id > 0) {
          contextMenuOptions.push({
              title : 'Edit folder',
              onClick : () => {this.editFolder(folder);},
          });

          contextMenuOptions.push({
              title : 'Delete folder',
              onClick : () => {this.deleteFolder(folder);},
          });
        }
      this.setState({
        contextMenuOptions : contextMenuOptions
      });
    }
    
    createScript(parentFolder) {
      this.props.onCreateScriptClick(parentFolder);
    }

    editScript(script) {
      this.props.editScriptClick(script);
    }

    deleteScript(script) {
      this.props.onDeleteScriptClick(script);
    }

    createFolder(parentFolder) {
      this.props.onCreateFolderClick(parentFolder);
    }

    editFolder(folder) {
      this.props.onEditFolderClick(folder);
    }

    deleteFolder(folder) {
      this.props.onDeleteFolderClick(folder);
    }

  render() {
    
    let folders = this.state.folders;
    let scripts = this.state.scripts;

    return (
        <div className="navigation background-color-lighter m-0 p-0">

          <ContextMenu id="context-menu" className="contextMenu background-color p-3">
            

            {_.map(this.state.contextMenuOptions, (option, key) => {
              return <MenuItem key={key} onClick={option.onClick} className="menu-item">
                      {option.title}
                    </MenuItem>
            })}
          </ContextMenu>
          
          {
            _.map(folders[0], (folder, key) => {
              return <Folder className="ml-1" key={key} onScriptClick={this.onScriptClick.bind(this)} onFolderClick={this.onFolderClick.bind(this)} folders={folders} scripts={scripts} script={scripts[folder.id]} {...folder}/>
            })
          }
        </div>
    );
  }
}

export default CMSNavigation;
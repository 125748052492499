const { handleGet, handlePost } = require("../ajax/AjaxRequests");

var _ = require('lodash');

class Controller {

    constructor() {
        // this.modelData = [
        //     {
        //         id : 1,
        //         types : [
        //             {
        //                 id : 25,
        //                 data : {
                            
        //                 }
        //             }
        //         ]
        //     }
        // ]

        this.modelData = [

        ]
    }

    getModelData(model, type = 'overview', data = undefined) {
        let typeID = this.getTypeID(model, type);
        let retrievedData = undefined;

        if(typeID === -1)
        {
            console.log('Unable to retrieve data, typeID is -1!');
            return retrievedData;   
        }

        if(type === 'overview')
            return handleGet(typeID).then((response) => {
                return this.onDataReceived(response, model, typeID);
            })
        else if(type === 'view' || type === 'form')
        {
            if(data === undefined) {
                console.log('Unable to retrieve view, data is undefined!');
                return retrievedData;
            }

            return handlePost(typeID, data).then((response) => {
                return this.onDataReceived(response, model, typeID);
            });
        }
    }

    onDataReceived(retrievedData, model, typeID) {
        if(this.containsModelData(model, typeID)) {
            let indexes = this.getIndexesByModel(model, typeID);
            this.modelData[indexes.modelIndex].types[indexes.typeIndex].data = retrievedData;
        } else {
            this.modelData.push(
                {
                    modelID : model.modelID,
                    types : [ 
                        {
                            id : typeID,
                            data : retrievedData
                        }
                    ]
                }
            )
        }
        return this.getModelDataByModel(model, typeID);
    }

    getTypeID(model, type) {
        if(type === 'overview')
            return model.overview;
        else if(type === 'view')
            return model.view;
        else if(type === 'form')
            return model.form;
        else    
            return -1;
    }

    containsModelData(model, typeID) {
        let foundData = false;
        for(let i = 0; i < this.modelData.length; i++) {
            let foundModel = this.modelData[i];
            _.forEach(foundModel.types, (type) => {
                if(foundModel.modelID === model.modelID && type.id === typeID) {
                    foundData = true;
                }
            });
        }

        return foundData;
    }

    getIndexesByModel(model, typeID) {
        let indexes = {modelIndex : -1, typeIndex : -1};
        if(!this.containsModelData(model, typeID))
            return indexes;

        for(let i = 0; i < this.modelData.length; i++) {
            let foundModel = this.modelData[i];
            for (let x = 0; x < foundModel.types.length; x++) {
                let type = foundModel.types[x];
                if(type.id === typeID && foundModel.modelID === model.modelID) {
                    indexes.modelIndex = i;
                    indexes.typeIndex = x;
                }
            }
        }


        return indexes;
    }

    /*this.modelData.push(
                {
                    id : model.id,
                    types : [ 
                        {
                            id : typeID,
                            data : retrievedData
                        }
                    ]
                }
            )*/

    getModelDataByModel(model, typeID) {
        if(!this.containsModelData(model, typeID))
            return undefined;
        
        let indexes = this.getIndexesByModel(model, typeID);
        return this.modelData[indexes.modelIndex].types[indexes.typeIndex].data;
    }

    filterByID(model, id) {
        let data = this.getModelDataByModel(model,);

        if(data === undefined)
            return data;

        let filteredData = {};

        _.forEach(data, (item) => {
            if(item.id === id)
                filteredData = item;
        });

        return filteredData;

    }
}

export default Controller;
import React, { Component } from 'react';
import './Folder.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ContextMenuTrigger } from 'react-contextmenu';
import _ from 'lodash';

class Folder extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }


  render() {
      let folders = this.props.folders;
      let scripts = this.props.scripts;
      
    return (
        <div className={`${this.props.className} mb-2`}>
            <ContextMenuTrigger id="context-menu">
                <div onContextMenu={() => {this.props.onFolderClick({id: this.props.id, name :this.props.name, parent_id: this.props.parent_id});}} className="color-blue folder">
                    {this.props.name} ({this.props.id})
                </div>
            </ContextMenuTrigger>
            <ContextMenuTrigger id="context-menu">
            {
                scripts !== undefined &&
                    _.map(scripts[this.props.id], (script, key) => {
                        return <div onContextMenu={() => {this.props.onScriptClick(script);}} key={key} className="indent script">{script.name} ({script.id})</div>
                    })
            }
            </ContextMenuTrigger>

             {
                folders !== undefined && 
                    _.map(folders[this.props.id], (folder, key) => {
                        return <Folder onScriptClick={this.props.onScriptClick.bind(this)} onFolderClick={this.props.onFolderClick} key={key} className="indent" folders={folders} scripts={scripts} script={scripts[folder.id]} {...folder} />
                })
            }
        </div>
    );
  }
}

export default Folder;
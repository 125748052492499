import React, { Component } from 'react';
import './Dashboard.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CMSNavigation from '../nav/CMSNavigation';
import { Container } from 'react-bootstrap';
import EditWindow from '../script/EditWindow';
import _ from 'lodash';
import EditTab from '../script/tabs/EditTab';
import {getUniqueID, removeItemOnce} from '../script/misc/Misc';
import Welcome from './Welcome';
import {handlePost} from '../../ajax/AjaxRequests';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeContent : '',
            editTabs : [],
            editWindows : [],
            activeEditTab : undefined,
            activeEditWindow : undefined,
            forceUpdate : false,
            savedActiveWindow : true,

        };

        this.deleteScriptID = 17;
        this.deleteFolderID = 18; 
    }


    onCreateFolderClick(parentFolder) {
        let newTab = {
            name : 'New Folder',
            type : 'folder',
            state : 'new',
            parentFolder : parentFolder,
        };

        this.createEditTab(newTab);
    }


    onCreateScriptClick(parentFolder) {
        let newTab = {
            name : 'New Script',
            type : 'script',
            state : 'new',
            parentFolder : parentFolder,
        };

        this.createEditTab(newTab);
    }

    editScriptClick(script) {
        let newTab = {
            name : script.name,
            type : 'script',
            state : 'edit',
            script : script,
            id : script.id,
        };

        this.createEditTab(newTab);
    }

    onDeleteScriptClick(script) {

        if(!window.confirm('Are you sure you wish to delete this script?')) 
            return;

        let data = [
            {
                key : 'script_id',
                value : script.id,
            },
        ];

        handlePost(this.deleteScriptID, data).then((response) => {
            let status = response['status'];

            if(status) {
                let activeEditWindow = this.state.activeEditWindow;
                
                if(activeEditWindow !== undefined) {
                    let tab = activeEditWindow.tab;
                    if(tab.id === script.id) {
                        this.onCloseButtonClick(tab);
                    }

                }
                this.updateNavigation();
            }
        });

        
    }

    onEditFolderClick(folder) {

        let newTab = {
            name : folder.name,
            type : 'folder',
            state : 'edit',
            folder : folder,
            id : folder.id,
        };

        this.createEditTab(newTab);
    }

    onDeleteFolderClick(folder) {

        if(!window.confirm('Are you sure you wish to delete this folder and its scripts?')) 
            return;

        let data = [
            {
                key : 'folder_id',
                value : folder.id,
            },
        ];

        handlePost(this.deleteFolderID, data).then((response) => {
            let status = response['status'];

            if(status) {
                let activeEditWindow = this.state.activeEditWindow;
                
                if(activeEditWindow !== undefined) {
                    let tab = activeEditWindow.tab;
                    if(tab.id === folder.id) {
                        this.onCloseButtonClick(tab);
                    }
    
                }
                this.updateNavigation();
            }
        });

        
    }
    
    updateTabs(editTabs, activeTab, newEditWindow, editWindows) {        
        this.setState({
            editTabs : editTabs,
            activeEditTab : activeTab,
            forceUpdate : true,
            activeEditWindow : newEditWindow,
            editWindows : editWindows
        });
    }

    createEditTab(newTab) {
        let editTabs = this.state.editTabs;
        editTabs.push(newTab);

        let editWindows = this.state.editWindows;
        let newEditWindow =  {
            tab : newTab,
            window : <EditWindow key={getUniqueID()} onCloseButtonClick={this.onCloseButtonClick.bind(this)} onEditTabTitleChanged={this.onEditTabTitleChanged.bind(this)} onEditWindowChanges={this.onEditWindowChanges.bind(this)} updateNavigation={this.updateNavigationAfterSave.bind(this)} activeEditTab={newTab}/>
        };

        editWindows.push(newEditWindow);

        this.updateTabs(editTabs, newTab, newEditWindow, editWindows);
    }

    

    onCloseButtonClick(editTab) {
        let editWindow = this.getEditWindowByEditTab(editTab).window;
        if(editWindow === this.state.activeEditWindow.window) {
            this.setState({
                activeEditWindow : undefined,
                activeEditTab : undefined,
            });
        }

        this.removeEditWindow(editWindow); 
        
    }

    onEditTabClick(editTab) {
        if(editTab === this.state.activeEditTab)
            return;

        let editWindow = this.getEditWindowByEditTab(editTab);

        this.setState( {
            activeEditWindow : editWindow,
            activeEditTab : editTab,
        });
        
    }

    getEditWindowByEditTab(editTab) {
        let editWindows = this.state.editWindows;

        for(let editWindow of editWindows) {
            let tab = editWindow.tab;

            if(editTab === tab)
                return editWindow;
        }

        return undefined;
    }

    removeEditWindow(clickedEditWindow) {
        let editWindows = this.state.editWindows;
        let editTabs = this.state.editTabs;
        let removedIndex = 0;

        for(let i = 0; i < editWindows.length; i++) {
            let item = editWindows[i];
            let window = item.window;

            if(clickedEditWindow === window) {
                removeItemOnce(editWindows, item);
                removeItemOnce(editTabs, item.tab);
                removedIndex = i;
            }
        }

        this.setState( {
            editWindows : editWindows,
        }, () => {
            let updatedEditWindows = this.state.editWindows;
            if(updatedEditWindows.length > 0) {
                if(removedIndex > 0) {
                    let newEditWindow = updatedEditWindows[removedIndex - 1];
                    this.onEditTabClick(newEditWindow.tab);
                } else {
                    let newEditWindow = updatedEditWindows[0];
                    this.onEditTabClick(newEditWindow.tab);
                }
            }
        });
    }

    updateNavigationAfterSave() {
        this.setState({
            forceUpdate : !this.state.forceUpdate,
            savedActiveWindow : true,
        });
    }

    updateNavigation() {
        this.setState({
            forceUpdate : !this.state.forceUpdate 
        });
    }

    onEditWindowChanges() {
        this.setState({
            savedActiveWindow : false,
        });
    }

    onEditTabTitleChanged(newTitle) {
        let activeEditTab = this.state.activeEditTab;
        activeEditTab.name = newTitle;

        this.setState({
            activeEditTab : activeEditTab,
        });
    }

    



  render() {

    let editTabs = this.state.editTabs;
    let activeEditTab = this.state.activeEditTab;
    let activeEditWindow = this.state.activeEditWindow;

    return (
        <div className="dashboard p-0 m-0 flex-direction-row">
            <CMSNavigation onDeleteFolderClick={this.onDeleteFolderClick.bind(this)} onEditFolderClick={this.onEditFolderClick.bind(this)} onDeleteScriptClick={this.onDeleteScriptClick.bind(this)} editScriptClick={this.editScriptClick.bind(this)}  onCreateScriptClick={this.onCreateScriptClick.bind(this)} onCreateFolderClick={this.onCreateFolderClick.bind(this)} key={this.state.forceUpdate} />
                
            <div className='w-100 h-100'>
                <div className="ml-5">
                    {
                        (activeEditWindow === undefined &&
                            <Welcome/>
                        )
                    }
                </div>
                <div className="ml-2 flex-direction-row mb-5">
                    {
                        _.map(editTabs, (editTab, key) => {
                            return <EditTab key={key} savedActiveWindow={this.state.savedActiveWindow} onCloseButtonClick={() => {this.onCloseButtonClick(editTab);}} onEditTabClick={() => {this.onEditTabClick(editTab);}} activeEditTab={(editTab === activeEditTab)} name={editTab.name} id={editTab.id}/>
                        })
                    }
                </div>

                <div className="ml-">
                    {
                        (activeEditWindow !== undefined &&
                            activeEditWindow.window
                        )
                    }
                </div>
            </div>
            
        </div>
    );
  }
}

export default Dashboard;
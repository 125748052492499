import React, { Component } from 'react';
import './Script.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form } from 'react-bootstrap';
// import { CodeEditorEditable } from 'react-code-editor-editable';
import AceEditor from "react-ace";
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';





class Script extends Component {

    constructor(props) {
        super(props);
        this.titleField = undefined;
        this.state = {

        };
    }

    componentDidMount() {
        this.titleField.focus();
    }

    

  /*<CodeEditorEditable
                value={this.props.scriptValue}
                setValue={this.props.setScriptValue}
                width="100%"
                height="1000px"
                language={this.props.language}
                inlineNumbers
            />*/
  render() {
    return (
        <div className="script-content h-100">
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>{this.props.newScript ? 'New' : 'Edit'} Script (CTRL+S to save.)</Form.Label>
                    <Form.Control ref={c => (this.titleField = c)} className="folder-title" type="text" placeholder="Title here" onChange={this.props.onScriptTitleChange} value={this.props.titleValue} />
                </Form.Group>
            </Form>
            <AceEditor
                width='100%'
                height='1000px'
                placeholder="Type your code"
                mode={this.props.language}
                theme="monokai"
                name="blah2"
                onChange={this.props.setScriptValue}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={this.props.scriptValue}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}/>
        </div>
    );
  }
}

export default Script;